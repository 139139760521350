import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, BreadCrumb, ComingSoon, Tags } from '../components/Section'
import { Link } from "gatsby"
import styled from 'styled-components'
import BgImage from '../images/blog.jpg'
import SecondaryLinkButton from "../components/Button/SecondaryLinkButton";
import { StaticImage } from "gatsby-plugin-image"
import BreakpointDown from "../components/Media/BreakpointDown";
import PlusIcon from '../components/Icons/PlusIcon'
import BreakpointUp from '../components/Media/BreakpointUp'


const SectionBgAfter = styled(Section)`
 &:after{
   right:0;
   left:0;
   background-size:cover;
 }
 .container{
   z-index:1;
 }
`
const BreadCrumbWhite = styled(BreadCrumb)`
 a{
   color:#fff;
   &:hover{
     color:#fff;
   }
 }
`
const PageTitle = styled.h1`
 margin-bottom:0;
 color:#fff;
 text-transform: capitalize;
`
const PageTitleWrapper = styled.div`
text-align:center;
color:#fff;
margin-top:10px;
${BreakpointDown.xl`
 margin-top:15px;
`}
`

// blog Card 
const BlogRow = styled.div`
 display:flex;
 flex-wrap:wrap;
 margin-left:-20px;
 margin-right:-20px;
 justify-content: center;
 ${BreakpointDown.xl`
    margin-left:-15px;
    margin-right:-15px;
 `}
`
const BlogColumn = styled.div`
 flex:0 0 25%;
 width:25%;
 padding-left:20px;
 padding-right:20px;
 margin-bottom:40px;
 @media(max-width:1800px){ 
  flex:0 0 33.33%;
  width:33.33%;
 }
 ${BreakpointDown.xl`
  padding-left:15px;
  padding-right:15px;
  margin-bottom:30px
 `}
 ${BreakpointDown.lg`
   flex:0 0 50%;
   width:50%;
 `}
  ${BreakpointDown.md`
   flex:0 0 100%;
   width:100%;
 `}
`

const Card = styled.div`
  padding: 70px 50px;
  background:#fff;
  height:100%;
  box-shadow: 0px 20px 45px rgb(0, 0, 0, 0.20);
  @media(max-width:1440px){
    padding: 20px 14px; 
  }
  ${BreakpointDown.lg`
   text-align:center;
 `}
  .btn{
   ${BreakpointUp.md`
     margin-top:15px;
   `}
  }
`
const CardTitle = styled(Link)`
 margin-bottom:6px;
 font-weight:bold;
 display:block;
`
const CardImg = styled.div`
  position: relative;
  max-width:100%;
  width:100%;
  margin-top:26px;
  .gatsby-image-wrapper{
    width:100%;
  }
`
const Date = styled.div`
  color:#666666;
  font-size:10px;
  letter-spacing: 2px;
  text-transform: uppercase;
  @media (min-width:2200px){
    font-size:14px;
  }
`

const CategoryFilter = styled.div`
 display:flex;
 flex-wrap:wrap;
 margin-left:-15px;
 margin-right:-15px;
 margin-top:30px;
 margin-bottom:20px;
 ${BreakpointDown.lg` 
   margin-top:10px;
   margin-bottom:30px;
 `}
`
const CategoryFilterLeft = styled.div`
flex:1;
padding-left:15px;
padding-right:15px;
${BreakpointDown.lg`
 margin-bottom:6px;
`}
`
const CategoryFilterRight = styled.div`
flex:0 0 422px;
padding-left:15px;
padding-right:15px;
${BreakpointDown.xl`
 flex:0 0 320px;
`}
${BreakpointDown.xl`
 flex:0 0 100%;
`}
.form-group{
  margin-bottom:0;
  .form-control{
    &::placeholder{
      color:#666;
    }
  }
}
`
const FilteredCategory = styled.div`
border: 1px solid #CCCCCC;
border-radius: 4px;
background:#fff;
display:inline-block;
padding:5px 6px 5px 11px;
line-height:1;
font-size:12px;
font-weight:bold;
margin-right:8px;


span{
 margin-left:5px;
 cursor: pointer;
 svg{ max-height:12px; transform:rotate(45deg)}
}
`


function BlogPage() {
  return (
    <Layout pageName="blog">
      <SEO title="Blog" />
      <SectionBgAfter pt="156px" pb="100px" xpt="80px" mpt="60px" mpb="40px" xpb="60px" bgAfter={`url(${BgImage})`}>
        <div className="container">
          <BreadCrumbWhite>
            <Link to="/">Home</Link><Link to="/#">Blog</Link>
          </BreadCrumbWhite>
          <PageTitleWrapper>
            <PageTitle>Articles & Guide</PageTitle>
            {/* <p>Lorem Ipsum is simply dummy text of the printing and type setting industry.</p> */}
          </PageTitleWrapper>
        </div>
      </SectionBgAfter>
      <Section bgColor="#f8f8f8" pb="60px" xpb="40px" pt="90px" pb="100px" xpt="80px" mpt="60px" mpb="40px" xpb="60px">
        <div className="container">
          {/* <CategoryFilter>
            <CategoryFilterLeft>
              <FilteredCategory>All Articles <span><PlusIcon /></span></FilteredCategory>
            </CategoryFilterLeft>
            <CategoryFilterRight>
              <div className="form-group">
                <select className="form-control" defaultValue="Select Articles Categories" placeholder="Select Articles Categories">
                  <option>Select Articles Categories</option>
                </select>
              </div>
            </CategoryFilterRight>
          </CategoryFilter> */}
          <BlogRow>
               <BlogColumn>
                  <Card> 
                    <Date>8 July, 2021</Date>
                    <CardTitle to="/move-your-business-into-the-future-with-voip-phone-systems" className="h6">Move Your Business Into the Future with VoIP Phone Systems</CardTitle>
                    <Tags to="/">Phone Systems</Tags> <Tags to="/">Business Communication</Tags>
                    <CardImg>
                      <StaticImage
                        src="../images/blog_3_blog-card.jpg"
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="VoIP Phone Systems blog Card"
                        placeholder="blurred"
                      />
                    </CardImg>
                    <Link to="/move-your-business-into-the-future-with-voip-phone-systems"><SecondaryLinkButton text="Read Full Blog" /></Link>
                  </Card>
                </BlogColumn>
                <BlogColumn>
                  <Card>
                    <Date>8 July, 2021</Date>
                    <CardTitle to="/everything-you-need-to-know-about-getting-an-access-control-system" className="h6">Everything You Need to Know About Getting an Access Control System</CardTitle>
                    <Tags to="/">Access Control System</Tags> <Tags to="/">Business Safety and Security</Tags>
                    <CardImg>
                      <StaticImage
                        src="../images/blog_1_blog-card.jpg"
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Access Control System blog Card"
                        placeholder="blurred"
                      />
                    </CardImg>
                    <Link to="/everything-you-need-to-know-about-getting-an-access-control-system"><SecondaryLinkButton text="Read Full Blog" /></Link>
                  </Card>
                </BlogColumn>
                <BlogColumn> 
                  <Card>
                    <Date>8 July, 2021</Date>
                    <CardTitle to="/get-the-right-point-of-sale-system-for-your-company-with-wyn-technologies" className="h6">Get the Right Point-of-Sale System for Your Company with WYN Technologies</CardTitle>
                    <Tags to="/">Point-of-Sale Systems</Tags> <Tags to="/">Retail Technology</Tags>
                    <CardImg>
                      <StaticImage
                        src="../images/blog_2_blog-card.jpg"
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="VoIP Phone Systems blog Card"
                        placeholder="blurred"
                      />
                    </CardImg>
                    <Link to="/get-the-right-point-of-sale-system-for-your-company-with-wyn-technologies"><SecondaryLinkButton text="Read Full Blog" /></Link>
                  </Card>
                </BlogColumn>
          </BlogRow>
        </div>

      </Section>
    </Layout>
  )
}
export default BlogPage 
